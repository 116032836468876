




import { readHasCreatorAccess, readHasAdminAccess } from '@/store/main/getters';
import { Component, Vue } from 'vue-property-decorator';
import { store } from '@/store';


const routerGuardCreator = async (to, from, next) => {
  if (!readHasCreatorAccess(store) && !readHasAdminAccess(store)) {
    next('/main');
  } else {
      next();
  }
};

@Component
export default class Content extends Vue {
  public beforeRouteEnter(to, from, next) {
    routerGuardCreator(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routerGuardCreator(to, from, next);
  }
}
